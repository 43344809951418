import '@fancyapps/ui';
import {Fancybox} from "@fancyapps/ui";
import InitDatepicker from "./init-datepicker";
import {InitMaskedinput} from "./init-maskedinput";
import {CraftForms} from "../block/craftForms";
import {FormsAjax} from "../block/formsAjax";

Fancybox.bind('[data-fancybox="gallery"] a', {
    groupAll: true,
});

Fancybox.bind('[data-fancybox=""]', {
    trapFocus: false,
    autoFocus: false,
    on: {
        done: (fancybox, eventName) => {
            new InitDatepicker();
            new InitMaskedinput();
            new CraftForms('.js-craft-form');
            new CraftForms('.js-craft-callback');
            new FormsAjax();
        },
    },
});

window.fbox = Fancybox;