import AirDatepicker from 'air-datepicker';

export default class InitDatepicker {
    constructor() {
        new AirDatepicker('.js-datepicker', {
            onSelect: function ({date, formattedDate, datepicker}) {
                datepicker.hide();
            }
        });
    }
}


window.airDatepicker = AirDatepicker;