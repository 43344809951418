import $ from 'jquery';

export class MobileMenu {
    constructor() {
        this.attachEvent();
    }

    attachEvent() {
        $('.js-show-mobile-menu').click(function (element) {
            element.preventDefault();

            $(this).toggleClass('is-active');
        });
    }
}