import '@fancyapps/ui';
import {Fancybox} from "@fancyapps/ui";

export class CraftForms {
    action = null;
    method = null;
    form = null;

    constructor(selector) {
        let form = document.querySelector(selector);

        if (!form) {
            console.log(`Форма ${selector} не найдена`);
            return false;
        }

        form.onsubmit = this.attachEvent.bind(this);
    }


    attachEvent(event) {
        event.preventDefault();

        this.form = event.target;

        let formData = new FormData(this.form);
        this.action = this.form.getAttribute('action');
        this.method = this.form.getAttribute('method');

        fetch(this.action, {
            method: this.method,
            body: formData
        })
            .then(response => response.json())
            .then(result => {

                let {code} = result;
                let {data} = result;

                this.resetErrors();

                // bad request
                if (code === 400) {
                    let {errors} = data;
                    this.errors(errors);
                }

                // success send form
                if (code === 200) {
                    this.resetForm();
                    this.success();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    errors(errors) {
        Object.keys(errors).map((propertyCode) => {


            let field = this.form.querySelector(`#field-${propertyCode}`);
            if (field.length <= 0) {
                return false;
            }

            let errorElement = field.querySelector('.site-form-error');
            if (errorElement.length <= 0) {
                return false;
            }

            errorElement.innerHTML = errors[propertyCode];
        });
    }

    success() {

        let url = new URL(this.action);
        url.searchParams.append('action', 'getSuccess');
        new Fancybox([
            {
                src: url,
                type: "ajax",
            },
        ]);
    }

    resetForm() {
        // this.form.querySelectorAll("input[type=file]").forEach(el => {
        //
        //     el.value = null;
        //
        //     const e = new Event("change");
        //     el.dispatchEvent(e);
        // });

        this.form.reset();
    }

    resetErrors() {
        // let formCommonErrorElementList = this.form.querySelectorAll('.common-form-element');
        let formErrorElementList = this.form.querySelectorAll('.site-form-error');

        // if (formCommonErrorElementList) {
        //     formCommonErrorElementList.forEach(el => {
        //         el.classList.remove('has-error');
        //     });
        // }

        if (formErrorElementList) {
            formErrorElementList.forEach(el => {
                el.innerHTML = '';
            });
        }
    }
}


window.craftForm = CraftForms;