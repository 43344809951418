import $ from 'jquery';


$('document').ready(function () {
    $('.js-hamb-source').click(function (event) {
        event.preventDefault();
        $('.js-hamb-target').toggleClass('active');
    });


    $(".more-menu").click((function () {
        $(this).next(".menu-sub").toggleClass('active_menu');
    }));
});
