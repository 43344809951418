import Swiper from 'swiper';
import {Navigation, Pagination, Thumbs} from 'swiper/modules';

document.addEventListener('DOMContentLoaded', () => {
    const modelsSwiper = new Swiper('.js-models-slider', {
        modules: [Navigation, Pagination],
        navigation: {nextEl: "#models-slider-next", prevEl: "#models-slider-prev"},
        slidesPerView: 3,
        spaceBetween: 67,
        loop: true,


        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                pagination: {
                    el: ".swiper-pagination",
                },
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: {
                    el: ".swiper-pagination",
                },
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 67,
                pagination: {
                    el: ".swiper-pagination",
                },
            },
            1300: {
                pagination: false,
                spaceBetween: 30,
                slidesPerView: 3,
            },
            1400: {
                slidesPerView: 3,
                spaceBetween: 30,
                pagination: false,
            },
        }
    });


    const profiSlider = new Swiper('.js-profi-slier', {
        modules: [Navigation, Pagination],
        slidesPerView: 3,
        spaceBetween: 70,
        loop: true,

        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                pagination: {
                    el: ".swiper-pagination",
                },
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: {
                    el: ".swiper-pagination",
                },
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2,
                spaceBetween: 67,
                pagination: {
                    el: ".swiper-pagination",
                },
            },
            // when window width is >= 640px
            1200: {
                slidesPerView: 3,
                spaceBetween: 67,
                pagination: false,
            }
        }
    });
});