import 'video.js';

import './include/jquery-code';
import './include/init-sliders';
import './include/init-fancy';

import {MobileMenu} from "./block/mobileMenu";
import {CraftForms} from "./block/craftForms";

new CraftForms('.js-craft-form');
new MobileMenu();