import $ from "jquery";
import '@fancyapps/ui';
import {Fancybox} from "@fancyapps/ui";


function openModal(src) {
    new Fancybox(
        [
            {
                src: src,
                type: 'ajax',
                tpl: {
                    closeButton: false,
                },
            },
        ],
        {}
    );
}

export class FormsAjax {
    constructor() {
        // Проверяем, был ли уже добавлен обработчик события отправки формы

        // Добавляем обработчик события отправки формы
        $('.js-form-ajax').submit(function (event) {
            event.preventDefault();

            var form = $(this)[0]; // Получаем объект формы
            var formData = new FormData(form); // Создаем объект FormData и передаем форму

            console.log(formData);
            $.ajax({
                url: $(this).attr('action'), // Получаем URL из атрибута action текущей формы
                data: formData,
                type: "POST",
                processData: false,
                contentType: false,

                success: function (response) {
                    console.log('Отправлено');
                    Fancybox.close();
                    setTimeout(function () {
                        openModal('/ajax/success.php');
                    }, 1000); // 2000 миллисекунд = 2 секунды
                }
            });
        });
        // Помечаем, что обработчик был добавлен

    }
}


